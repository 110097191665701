import FetchPlus from "./FetchPlus";

const catalogueBase = "https://numar-api-prod.polpodev.com";

export const fetchGetCatalogue = () => {
  const options = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return FetchPlus(`${catalogueBase}/catalogue`, options)
    .then((response) => response || [])
    .catch((err) => console.log(err));
};
