/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useLayoutEffect, useState } from "react";
//library
import { ImSpinner9 } from "react-icons/im";
import Localbase from "localbase";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
	BsFillArrowRightCircleFill,
	BsFillArrowLeftCircleFill,
} from "react-icons/bs";
//queries
// import { Catalogues } from "GraphQL/Queries";
//service
import { fetchGetCatalogue } from "Service/CatalogueService";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

const dowloadLastPDF = async (
	db,
	base64,
	setdataBase64IndexedDb,
	url,
	totalPages
) => {
	// here wait the dataBase State
	if (base64) {
		//here save in indexedDB
		await db.collection("catalogueData").doc(url).set(
			{
				url: url,
				base64: base64,
				totalPages: totalPages,
			},
			url
		);
		//this read indexDB data
		const dataIndexddb = await db
			.collection("catalogueData")
			.doc(url)
			.get()
			.then((catalogue) => catalogue);

		//here insert in stage
		setdataBase64IndexedDb({
			indexedFile: dataIndexddb.base64,
			totalPages: dataIndexddb.totalPages,
		});
	}
};

const getLastPDFFromDatabase = async (db, setdataBase64IndexedDb) => {
	//here load indexDB data
	const lastPDF = await db
		.collection("catalogueData")
		.limit(1)
		.get()
		.then((pdf) => pdf);

	setdataBase64IndexedDb({
		indexedFile: lastPDF[0].base64,
		totalPages: lastPDF[0].totalPages,
	});
};

const isNewVersion = async (
	db,
	url,
	base64,
	setdataBase64IndexedDb,
	totalPages
) => {
	//here get localDataBase data
	if (url && base64) {
		const dataIndexddb = await db
			.collection("catalogueData")
			.doc(url)
			.get()
			.then((catalogue) => catalogue);

		//if dataIndexddb if null didn't have new version
		if (!dataIndexddb) {
			const lastPDF = await db
				.collection("catalogueData")
				.get()
				.then((catalogue) => catalogue);

			await db.collection("catalogueData").doc(lastPDF[0].url).delete();

			//this save new url in indexedDB
			await db.collection("catalogueData").add(
				{
					url: url,
					base64: base64,
					totalPages: totalPages,
				},
				url
			);

			//this read indexDB data
			const dataIndexddb = await db
				.collection("catalogueData")
				.doc(url)
				.get()
				.then((catalogue) => catalogue);

			// here insert in stage
			setdataBase64IndexedDb({
				indexedFile: dataIndexddb.base64,
				totalPages: dataIndexddb.totalPages,
			});
		} else {
			//here load dataIndexDB
			getLastPDFFromDatabase(db, setdataBase64IndexedDb);
		}
	}
};
//this method create base64pdf
const generateToBase64 = async (url) => {
	const response = await fetch(url, {
		method: "GET",
	}).then((response) => response.blob());
	const reader = new FileReader();
	await new Promise((resolve, reject) => {
		reader.onload = resolve;
		reader.onerror = reject;
		reader.readAsDataURL(response);
	});
	return reader.result.replace(/^data:.+;base64,/, "");
};
//this is GraphQl query
const CatalogueServiceFetch = async () => {
	// const data = await client.query({ query: Catalogues }).then((rest) => rest);
	const data = await fetchGetCatalogue().then((rest) => rest);
	return data;
};

//this catch url
const getUrlFromRequest = (request) => {
	return {
		url: "https://polpo-assets.s3.amazonaws.com/Cat%C3%A1logo+Digital+Grupo+Numar/PDF/Catalogo-Numar-Link-v12_compressed.pdf",
		totalPages: request?.total_pages,
	};
};

const getTotalPage = (nuberOfPage) => {
	const allPages = [];

	for (let i = 1; i < nuberOfPage; i++) {
		allPages.push(i);
	}
	return allPages;
};
const Catalogue = () => {
	const [dataBase64IndexedDb, setdataBase64IndexedDb] = useState();
	const [mobileMsg, setMobileMsg] = useState({
		show: false,
		message: "",
	});

	const [message, setMessage] = useState({ message: "" });
	//getTotalPages

	useEffect(async () => {
		const online = window.navigator.onLine;

		//here comfirm if dataBase exicts
		const dbName = "catalogue";
		const isExisting = (await window.indexedDB.databases())
			.map((db) => db.name)
			.includes(dbName);

		if (!isExisting) {
			if (online) {
				const request = await CatalogueServiceFetch();
				if (request) {
					const { url, totalPages } = getUrlFromRequest(request);
					const base64 = await generateToBase64(url);

					const db = new Localbase("catalogue");
					db.config.debug = false;
					await dowloadLastPDF(
						db,
						base64,
						setdataBase64IndexedDb,
						url,
						totalPages
					);
				}
			} else {
				setMessage({ message: "Actualmente no esta en linea." });
			}
		} else {
			const db = new Localbase("catalogue");
			db.config.debug = false;

			if (online) {
				const request = await CatalogueServiceFetch();
				const { url, totalPages } = getUrlFromRequest(request);
				const base64 = await generateToBase64(url);
				isNewVersion(db, url, base64, setdataBase64IndexedDb, totalPages);
			} else {
				getLastPDFFromDatabase(db, setdataBase64IndexedDb);
			}
		}
	}, []);

	const [numPages, setNumPages] = useState(1);
	const [totalPage, setotalPage] = useState();

	function onDocumentLoadSuccess({ numPages }) {
		setotalPage(numPages);
	}

	// validate if mobile or desktop width

	useLayoutEffect(() => {
		const isMobile = window.screen.width < 568;
		if (isMobile && numPages >= 54) {
			setMobileMsg({
				show: true,
				message:
					"Para una mejor experiencia, por favor, gire su dispositivo, de ser necesario recargue la página.",
			});

			// create interval to delete
			const interval = setInterval(() => {
				setMobileMsg({
					show: false,
					message: "",
				});
			}, 3000);
			return () => clearInterval(interval);
		} else {
			setMobileMsg({
				show: false,
				message: "",
			});
		}
	}, [numPages]);

	const [deferredPrompt, setDeferredPrompt] = useState(null);
	const [updateApp, setUpdateApp] = useState(true);

	useEffect(() => {
		const handleBeforeInstallPrompt = (e) => {
			e.preventDefault();
			setDeferredPrompt(e);
		};
		// si es un iphone no se muestra el mensaje de instalacion
		if ( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
			setUpdateApp(false);
		}

		// valida si la aplicacion ya esta instalada
		if (window.matchMedia("(display-mode: standalone)").matches) {
			setUpdateApp(false);
		}

		window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

		return () => {
			window.removeEventListener(
				"beforeinstallprompt",
				handleBeforeInstallPrompt
			);
		};
	}, []);

	const handleInstallClick = () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			deferredPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === "accepted") {
					console.log("El usuario ha aceptado la instalación de la PWA");
				} else {
					console.log("El usuario ha cancelado la instalación de la PWA");
				}
				setDeferredPrompt(null);
				setUpdateApp(false);
			});
		}
		setUpdateApp(false);
		// encaso de que no se pueda instalar
		if (!deferredPrompt) {
			alert('Para instalar la PWA, toca el ícono de compartir y selecciona "Agregar a la pantalla de inicio".');
		}
	};

	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		// Función para manejar el cambio en el ancho de la pantalla
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		// Agregar un event listener para el evento de cambio de tamaño de la ventana
		window.addEventListener("resize", handleResize);

		// Limpia el event listener cuando el componente se desmonta
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [window.innerWidth]);

	
	useEffect(() => {
		const handleOrientationChange = () => {
		  // Recarga la página cuando cambia la orientación del dispositivo
			// solo si es iphone
			if ( /iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
				setWindowWidth(`${window.innerWidth + "px"}`);
				console.log('orientation changed');
			}
		};
	
		window.addEventListener('orientationchange', handleOrientationChange);
	
		return () => {
		  window.removeEventListener('orientationchange', handleOrientationChange);
		};
	  }, []);

	return (
		<div className="min-w-full min-h-full"
			key={windowWidth}
		>
			{message && (
				<span className="text-red-600 text-2xl font-bold">
					{message.message}
				</span>
			)}
			{updateApp && (
				<div
					className={`fixed bottom-0 left-0 w-screen h-16 z-50 flex justify-center items-center
					text-gray-500 text-center text-xs md:text-lg font-bold bg-gray-50 shadow-xl
					animate__animated ${updateApp ? "animate__fadeInUp" : "animate__fadeOutDown"}
					`}
				>
					<p>¿Deseas instalar la aplicación 📲?</p>
					<button
						onClick={() => handleInstallClick()}
						className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
					>
						Si ✨
					</button>
					<button
						onClick={() => setUpdateApp(false)}
						className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded ml-4"
					>
						No 😔
					</button>
				</div>
			)}
			<div className="w-full h-full flex justify-center gap-0 items-center flex-col ">
				{dataBase64IndexedDb && getTotalPage ? (
					<>
						<Document
							className="w-auto h-full flex flex-col justify-center"
							file={`data:application/pdf;base64,${dataBase64IndexedDb.indexedFile}`}
							onLoadSuccess={onDocumentLoadSuccess}
							onItemClick={({ pageNumber }) => {
								setNumPages(pageNumber);
							}}
						>
							<Page
								width={(window.screen.width * 90) / 100}
								loading={
									<div className="w-full h-full flex justify-center items-center">
										<ImSpinner9 className="text-white  font-bold text-8xl animate-spin" />
									</div>
								}
								pageNumber={numPages}
							/>
							{mobileMsg.show && (
								<div
									className="w-screen absolute top-0 left-0 z-50 text-white text-center fade-in-top"
									onClick={() => {
										//if i press rotate the phone create the function to rotate the phone
										window.screen.orientation.lock("landscape");
									}}
								>
									<div className="w-full">
										<div
											className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
											role="alert"
										>
											<span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
												🤳
											</span>
											<span className="font-semibold mr-2 text-left flex-auto">
												{mobileMsg.message}
											</span>
											<svg
												className="fill-current opacity-75 h-4 w-4"
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 20 20"
											>
												<path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
											</svg>
										</div>
									</div>
								</div>
							)}
							{numPages !== 1 && (
								<button
									className={`bg-blue-500 hover:bg-blue-700 text-white font-bold 
                  						rounded relative w-auto self-start py-1 px-1 
                  						text-sm lg:text-2xl lg:py-2 lg:px-4
                  						transition duration-500 ease-in-out 
										${
											numPages >= 54
												? "left-4 -bottom-3 lg:bottom-1 lg:left-8 z-50"
												: "bottom-10 left-4 lg:bottom-32 lg:left-20 "
										}`}
									onClick={() => {
										numPages >= 54 ? setNumPages(2) : setNumPages(1);
									}}
								>
									Menu
								</button>
							)}
							{numPages !== 1 && (
								<div className="w-full py-4 flex justify-center gap-4 bg-white relative bottom-7 xl:bottom-12">
									<BsFillArrowLeftCircleFill
										onClick={() => {
											numPages <= 0 ? 1 : setNumPages(numPages - 1);
										}}
										className="text-xl xl:text-6xl cursor-pointer"
									/>
									<BsFillArrowRightCircleFill
										onClick={() => {
											numPages === totalPage
												? totalPage
												: setNumPages(numPages + 1);
										}}
										className="text-xl xl:text-6xl cursor-pointer"
									/>
								</div>
							)}
						</Document>
					</>
				) : (
					<div className="w-full h-full flex justify-center items-center">
						<ImSpinner9 className="text-white  font-bold text-8xl animate-spin" />
					</div>
				)}
			</div>
		</div>
	);
};

export default Catalogue;
