import React, { useEffect, useState } from "react";
import Catalogue from "Views/Catalogue/Catalogue";
import OneSignal from "react-onesignal";
import Localbase from "localbase";
import "./landsCape.css";

let appVersion = "1.0.5";

function CatalogueApp() {
	const matchesMediaQuery = window.matchMedia(
		// si esta entre 800 y 900px
		"(min-width: 800px) and (max-width: 900px)"
	).matches;

	useEffect(() => {
		OneSignal.init({
			appId: "0daa29a4-3bd5-40cd-82ce-de50fbe2d026",
		});
	}, []);
	const [updateApp, setUpdateApp] = useState(false);

	useEffect(() => {
		
		const storedVersion = localStorage.getItem("appVersion");
		if(storedVersion === null) localStorage.setItem("appVersion", appVersion);
		if(storedVersion === undefined) localStorage.setItem("appVersion", appVersion);
		if(storedVersion === "undefined") localStorage.setItem("appVersion", appVersion);

		console.log("storedVersion", storedVersion);

		if (storedVersion !== appVersion) {
			console.log("Update app");
			setUpdateApp(true);
		} else {
			console.log("App is up to date");
			setUpdateApp(false);
		}
	}, []);

	const clearApp = async () => {
		setUpdateApp(false);
		localStorage.clear();
		sessionStorage.clear();

		// reset the cache
		if (caches) {
			// Service worker cache should be cleared with caches.delete()
			caches.keys().then(function (names) {
				for (let name of names) caches.delete(name);
			});
		}
		//delete the database
		const db = new Localbase("catalogue");
		db.config.debug = false;
		await db.delete();
		// reload the page

		indexedDB.deleteDatabase("catalogue");
		indexedDB.deleteDatabase("test");

		localStorage.setItem("appVersion", appVersion);

		window.location.reload();
	}

	


	return (
		<div className="min-h-screen bg-gray-800 flex">
			{
				updateApp && (
					<div className="fixed top-20 left-0 w-screen h-16 z-50 flex justify-center items-center
					animate__animated animate__fadeInDown
					">
						<div className="bg-white shadow-2xl rounded-lg p-4 ring-1 ring-gray-300
							
						">
							<p className="text-center text-lg md:text-xl lg:text-2xl font-bold">¡Actualiza la aplicación 🎉!</p>
							<p className="text-center text-sm md:text-base lg:text-lg font-bold">Nueva versión disponible</p>
							<p className="text-center text-sm md:text-base lg:text-lg font-bold">¿Deseas actualizar?</p>
							<div className="flex justify-center gap-4 mt-4">
								<button 
									onClick={() => clearApp()}
									className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
								>
									Si
								</button>
								<button onClick={() => setUpdateApp(false)} className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded">
									No
								</button>
							</div>
						</div>
					</div>
				)
			}
			<div id="" className="hidden">
				<div>
					<p className="text-center">
						<img
							className="animate-pulse"
							src="https://polpo-assets.s3.amazonaws.com/Cat%C3%A1logo+Digital+Grupo+Numar/Image/screen-rotation-icon-vector-isolated-260nw-1753856981+(1).png"
							alt="landcase"
						/>
						¡Gira el móvil! <br />
					</p>
				</div>
			</div>
			{!matchesMediaQuery && <Catalogue />}
		</div>
	);
}

export default CatalogueApp;
