export default function FetchPlus(url, options = {}, retries = 3) {
  return fetch(url, options)
    .then(async (res) => {
      if (res.ok) {
        return res.json();
      } else {
        throw res.status;
      }
    })
    .catch((err) => {
      if (retries > 0) {
        return FetchPlus(url, options, retries - 1);
      }
      throw err;
    });
}
